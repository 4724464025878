<template>
  <div class="">
    <PhoneEditor />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { PhoneEditor } from "@/components/Page";
export default defineComponent({
  components: {
    PhoneEditor,
  },
  setup() {
    return {};
  },
});
</script>